<template>
  <div class="width100 box-sizing padding3 no-result">
    <div class="flex flex-center padding1 box-sizing">
      <el-checkbox v-model="thisCity">
        <span>同城</span>
      </el-checkbox>
      <el-checkbox v-model="thisProvince">
        <span>同省</span>
      </el-checkbox>
      <el-dropdown @command="handleCommand" class="margin-left5">
        <div class="el-dropdown-link">
          {{ renderTime }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="1">1小时内</el-dropdown-item>
          <el-dropdown-item :command="2">最近3小时</el-dropdown-item>
          <el-dropdown-item :command="3">最近5小时</el-dropdown-item>
          <el-dropdown-item :command="4">最近12小时</el-dropdown-item>
          <el-dropdown-item :command="5">最近1天</el-dropdown-item>
          <el-dropdown-item :command="6">最近3天</el-dropdown-item>
          <el-dropdown-item :command="7">最近5天</el-dropdown-item>
          <el-dropdown-item :command="8">最近10天</el-dropdown-item>
          <el-dropdown-item :command="0">不限时间</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="flex1"></div>
    </div>
    <div class="margin-top2 padding1 box-sizing min-height500">
      <div class="width100">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="keywords" label="查询条件" width="180">
          </el-table-column>
          <el-table-column prop="createdAt" label="查询时间" width="180">
            <template slot-scope="scope">
              <div>
                {{ scope.row.createdAt }} 来自 {{ scope.row.city }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="companyName" label="查询方">
            <template slot-scope="scope">
              <div>
                <span v-if='scope.row.companyName'>{{ scope.row.companyName }}-</span>
                <span v-if='scope.row.name'>{{ scope.row.name }}-</span>
                <span v-if='scope.row.phone'>{{ scope.row.phone }}</span>
                <span v-if="scope.row.companyType === 'dealer'" class="text-warning">(经销商)</span>
                <span v-if="scope.row.companyType === 'garage'" class="text-warning">(汽修厂)</span>
                <span v-if="scope.row.companyType === 'manufacturer'" class="text-warning">(生产厂家)</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <div>
                <span class="text-warning cursor-pointer actives05" v-if="!$store.state.profile.id"
                      @click="$router.push('/login')">登录后可联系对方</span>
                <span class="text-warning cursor-pointer actives05" v-if="$store.state.profile.id && !$store.state.profile.companyAuthed"
                      @click="auth">认证后可联系对方</span>
                <div class="w-16 h-16 cursor-pointer cursor-pointer actives05" v-if="$store.state.profile.id && $store.state.profile.companyAuthed"
                     @click="sendMsg(scope.row)">
                  <svg t="1667186969453" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1555" width="16" height="16">
                    <path
                      d="M664.250054 368.541681c10.015098 0 19.892049 0.732687 29.67281 1.795902-26.647917-122.810047-159.358451-214.077703-310.826188-214.077703-169.353083 0-308.085774 114.232694-308.085774 259.274068 0 83.708494 46.165436 152.460344 123.281791 205.78483l-30.80868 91.730191 107.688651-53.455469c38.558178 7.53665 69.459978 15.308661 107.924012 15.308661 9.66308 0 19.230993-0.470721 28.752858-1.225921-6.025227-20.36584-9.521864-41.723264-9.521864-63.862493C402.328693 476.632491 517.908058 368.541681 664.250054 368.541681zM498.62897 285.87389c23.200398 0 38.557154 15.120372 38.557154 38.061874 0 22.846334-15.356756 38.156018-38.557154 38.156018-23.107277 0-46.260603-15.309684-46.260603-38.156018C452.368366 300.994262 475.522716 285.87389 498.62897 285.87389zM283.016307 362.090758c-23.107277 0-46.402843-15.309684-46.402843-38.156018 0-22.941502 23.295566-38.061874 46.402843-38.061874 23.081695 0 38.46301 15.120372 38.46301 38.061874C321.479317 346.782098 306.098002 362.090758 283.016307 362.090758zM945.448458 606.151333c0-121.888048-123.258255-221.236753-261.683954-221.236753-146.57838 0-262.015505 99.348706-262.015505 221.236753 0 122.06508 115.437126 221.200938 262.015505 221.200938 30.66644 0 61.617359-7.609305 92.423993-15.262612l84.513836 45.786813-23.178909-76.17082C899.379213 735.776599 945.448458 674.90216 945.448458 606.151333zM598.803483 567.994292c-15.332197 0-30.807656-15.096836-30.807656-30.501688 0-15.190981 15.47546-30.477129 30.807656-30.477129 23.295566 0 38.558178 15.286148 38.558178 30.477129C637.361661 552.897456 622.099049 567.994292 598.803483 567.994292zM768.25071 567.994292c-15.213493 0-30.594809-15.096836-30.594809-30.501688 0-15.190981 15.381315-30.477129 30.594809-30.477129 23.107277 0 38.558178 15.286148 38.558178 30.477129C806.808888 552.897456 791.357987 567.994292 768.25071 567.994292z"
                      fill="#F3D146" p-id="1556">
                    </path>
                  </svg>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex margin-top2">
          <div class="flex1"></div>
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="pageSize"
            :total="total"
            @current-change="pageChange">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getSearchUnfoundApi} from "@/api/home";
import {STATUS} from "../../../store/index";

export default {
  data() {
    return {
      keyword: "",
      thisCity: false,
      thisProvince: false,
      lastTimeQuery: 0,
      pageSize: 12,
      page: 1,
      total: 0,
      tableData: [],
    };
  },
  watch: {
    thisCity() {
      this.query();
    },
    thisProvince() {
      this.query();
    },
  },
  computed: {
    renderTime() {
      if (!this.lastTimeQuery) {
        return "筛选时间";
      }
      const titleArr = [
        "1小时以内",
        "最近3小时",
        "最近5小时",
        "最近12小时",
        "最近1天",
        "最近3天",
        "最近5天",
        "最近10天",
      ];
      return titleArr[this.lastTimeQuery - 1];
    },
    hasCookie() {
      return this.$store.state.profile.id;
    },
  },
  mounted() {
    this.query();
  },
  methods: {
    pageChange(e) {
      this.page = e;
      this.query();
    },
    auth() {
      this.$store.dispatch("changeAuthStatus", STATUS.AUTH);
      this.$router.push("/login");
    },
    query() {
      getSearchUnfoundApi({
        page: this.page,
        pageSize: this.pageSize,
        keyword: this.keyword,
        thisCity: this.thisCity,
        thisProvince: this.thisProvince,
        lastTimeQuery: this.lastTimeQuery,
      }).then((res) => {
        this.tableData = res.rows || [];
        this.total = res.totalSize;
      });
    },
    handleCommand(e) {
      this.lastTimeQuery = e;
      this.query();
    },
    sendMsg(e) {
      if (!this.$store.state.profile.id) {
        this.$router.push("/login");
        return;
      }
      this.$store.dispatch("setChatId", e.userId);
      this.$store.dispatch("changeIMVisible", true);
    },
  },
};
</script>
